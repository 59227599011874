var Emico = Emico || {};

Emico.ColumnsShift = class {
    constructor(options) {
        this.setMobile = this.setMobile.bind(this);
        this.setDesktop = this.setDesktop.bind(this);
        this.columnShiftInit = this.columnShiftInit.bind(this);

        this.options = jQuery.extend({}, {
            col3Layout: '.main-container.col3-layout',
            rightColumn: '.col-right',
            colWrapper: '.col-wrapper',
            main: '.main'
        }, options);

        this.columnShiftInit();

    }

    setMobile() {
        const rightColumn = jQuery(this.options.rightColumn);
        const colWrapper = jQuery(this.options.colWrapper);

        rightColumn.appendTo(colWrapper);
    }

    setDesktop() {
        const rightColumn = jQuery(this.options.rightColumn);
        const main = jQuery(this.options.main);

        rightColumn.appendTo(main);
    }

    columnShiftInit() {
        if (jQuery(this.options.col3Layout).length > 0) {
            enquire.register('screen and (max-width:' + bp.large + 'px)', {
                match: this.setMobile,
                unmatch: this.setDesktop
            });
        }
    };
};

jQuery(() => {
    new Emico.ColumnsShift();
});